@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

:root {
  --background: #f0f2f5;
  --red: #e52e4d;
  --blue: #5429cc;
  --green: #1ca48f;
  --orange: #c4890c;
  --text-title: #363f5f;
  --text-body: #969cb3;
  --shape: #ffffff;
  --primary-color0: #2a9eda;
  --blue-light: #4885ed;
  --blue-primary-color: #172b44;
  --blue-primary-color-1: #24436a;
  --dark-primary-color: #4444c0;
  --dark-primary-color-1: #2c2b3c;
  --gray: #707070;
  --white: #fbfbfb;
  --pink0: #f9004b;
  --pink-1: #f92565;
  --pink1: #d40040;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f0f2f5;
  background: var(--background);
  -webkit-font-smothing: antialiased;
  background-color: #f0f2f5;
  background-color: var(--background);
  color: #707070;
  color: var(--gray);
}

ul {
  list-style: none;
}

input,
button,
textarea {
  font: 400 16px Roboto, sans-serif;
}

h1 {
  color: #707070;
  color: var(--gray);
}

button {
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

form select {
  margin-bottom: 1rem;
  height: 3rem !important;
}

form input {
  cursor: pointer;
  padding: 16px;
  height: 4rem;
  width: 100%;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  color: #969cb3;
  color: var(--text-body);
}
form input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #969cb3;
  color: var(--text-body);
}

form button {
  cursor: pointer;
  padding: 16px;
  height: 4rem;
  width: 100%;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  background-color: #2a9eda;
  background-color: var(--primary-color0);
  color: #fbfbfb;
  color: var(--white);
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

form button:hover {
  -webkit-filter: brightness(0.9) !important;
          filter: brightness(0.9) !important;
}

#button-default {
  margin-top: 8px;
  padding: 8px;
  height: 4rem;
  border-radius: 4px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  display: inline-block !important;
  font-size: 0.8rem;
  border: none !important;
  color: var(--white);
}

#button-default:hover{
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.button-primary {
  background-color: var(--primary-color0);
}

#modalConfig {
  box-sizing: border-box;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.25);
}

#modalConfig select {
  height: 5rem !important;
  width: 100% !important;
}

#buttonDefault {
  padding: 8px;
  height: 3rem;
  border-radius: 4px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  display: inline-block !important;
  font-size: 0.8rem;
  border: none !important;
}

.link-primary {
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  color: var(--white) !important;
  background-color: var(--pink0);
  text-decoration: none;
}

.link-secondary {
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  color: var(--text-body) !important;
  background-color: var(--background);
}

.link-primary:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
}

.link-secondary:hover {
  color: var(--white) !important;
  background-color: var(--pink0) !important;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
}

.link-red {
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  color: var(--white) !important;
  background-color: var(--red);
}
.link-green {
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  color: var(--white) !important;
  background-color: var(--green);
}

.loader {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-blue {
  position: absolute;
  background-color: #1f3f77;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

