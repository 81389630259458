.loader {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-blue {
  position: absolute;
  background-color: #1f3f77;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
