#button-default {
  margin-top: 8px;
  padding: 8px;
  height: 4rem;
  border-radius: 4px;
  transition: filter 0.2s;
  display: inline-block !important;
  font-size: 0.8rem;
  border: none !important;
  color: var(--white);
}

#button-default:hover{
  filter: brightness(0.9);
}

.button-primary {
  background-color: var(--primary-color0);
}
