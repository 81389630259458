@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

:root {
  --background: #f0f2f5;
  --red: #e52e4d;
  --blue: #5429cc;
  --green: #1ca48f;
  --orange: #c4890c;
  --text-title: #363f5f;
  --text-body: #969cb3;
  --shape: #ffffff;
  --primary-color0: #2a9eda;
  --blue-light: #4885ed;
  --blue-primary-color: #172b44;
  --blue-primary-color-1: #24436a;
  --dark-primary-color: #4444c0;
  --dark-primary-color-1: #2c2b3c;
  --gray: #707070;
  --white: #fbfbfb;
  --pink0: #f9004b;
  --pink-1: #f92565;
  --pink1: #d40040;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: var(--background);
  -webkit-font-smothing: antialiased;
  background-color: var(--background);
  color: var(--gray);
}

ul {
  list-style: none;
}

input,
button,
textarea {
  font: 400 16px Roboto, sans-serif;
}

h1 {
  color: var(--gray);
}

button {
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

form select {
  margin-bottom: 1rem;
  height: 3rem !important;
}

form input {
  cursor: pointer;
  padding: 16px;
  height: 4rem;
  width: 100%;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  color: var(--text-body);
}
form input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--text-body);
}

form button {
  cursor: pointer;
  padding: 16px;
  height: 4rem;
  width: 100%;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  background-color: var(--primary-color0);
  color: var(--white);
  transition: filter 0.2s;
}

form button:hover {
  filter: brightness(0.9) !important;
}
