#buttonDefault {
  padding: 8px;
  height: 3rem;
  border-radius: 4px;
  transition: filter 0.2s;
  display: inline-block !important;
  font-size: 0.8rem;
  border: none !important;
}

.link-primary {
  transition: filter 0.2s;
  color: var(--white) !important;
  background-color: var(--pink0);
  text-decoration: none;
}

.link-secondary {
  transition: filter 0.2s;
  color: var(--text-body) !important;
  background-color: var(--background);
}

.link-primary:hover {
  filter: brightness(0.9);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
}

.link-secondary:hover {
  color: var(--white) !important;
  background-color: var(--pink0) !important;
  filter: brightness(0.9);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
}

.link-red {
  transition: filter 0.2s;
  color: var(--white) !important;
  background-color: var(--red);
}
.link-green {
  transition: filter 0.2s;
  color: var(--white) !important;
  background-color: var(--green);
}
